import React from 'react'
import { Order, OrderItem, Package } from 'depoto-core/dist/src/entities'
import { Fn } from 'depoto-core/dist/src/models'
import { OrderItemRow } from './OrderItemRow'
import { PackageDetail } from './PackageDetail'
import { Btn } from './Btn'
import { useCore } from '../hooks'

type Props = {
  order: Order
  onUpdate: Fn
}
export const OrderItemsInPackages: React.FC<Props> = ({ order, onUpdate }) => {
  const packages: Package[] = order.packages || []
  const orderItemsInFirstPackage: OrderItem[] = packages[0]?.items?.filter(oi => oi.type === 'product') || []
  const { core, currentOrder, isFetching } = useCore()

  const addPackage = async (isEmpty?: boolean) => {
    const items = isEmpty
      ? []
      : order.packages?.length > 0
      ? orderItemsInFirstPackage.length > 1
        ? // ? [orderItemsInFirstPackage[orderItemsInFirstPackage.length - 1]] // todo: all unpacked orderItems to next Package
          [...orderItemsInFirstPackage.filter(oi => !oi.packed)]
        : []
      : [...order.items]
    try {
      const res = await core?.services.pack.create({
        carrier: currentOrder?.carrier,
        order: currentOrder,
        items,
      })
      if (res && res.id > 0) {
        onUpdate()
        return true
      } else {
        alert('Chyba při zakládáni balíku')
        return false
      }
    } catch (errors) {
      alert(
        'Chyba při zakládáni balíku\n' + Array.isArray(errors) && errors.length
          ? errors.map((e: any) => e.message).join('\n')
          : '',
      )
      return false
    }
  }

  const addPackages = async () => {
    const quantity = Number(prompt('Kolik?', '2'))
    if (isNaN(quantity)) {
      alert('Zadejte platné číslo')
      return
    }
    const q = new Array(quantity)
    for (const _ of q) {
      await addPackage(true)
    }
  }

  return (
    <div>
      {packages.map((pack, i) => {
        const orderItems: OrderItem[] = pack.items?.filter(oi => oi.type === 'product') || []
        return (
          <div key={pack.id}>
            <PackageDetail pack={pack} index={i + 1} onUpdate={onUpdate} />
            <div
              className={`grid ${
                currentOrder?.processStatus?.id === 'packing' ? 'grid-cols-13' : 'grid-cols-12'
              } leading-10`}>
              {/*HLAVICKA TABULKY*/}
              <div className="col-start-1 col-span-6 border-b font-medium text-shadow-grey pl-20">Název zboží</div>
              <div className="border-b"></div>
              <div className="border-b font-medium text-shadow-grey">Kód</div>
              <div className="border-b font-medium text-shadow-grey">EAN</div>
              <div className="border-b font-medium text-shadow-grey text-center">Počet ks</div>
              <div className="border-b"></div>
              <div className="border-b">{currentOrder?.processStatus?.id === 'packing' && 'Přesun do balíku'}</div>
            </div>
            <div className="table-stripped">
              {orderItems.map(oi => (
                <OrderItemRow
                  key={`${order.id}-${oi.id}`}
                  orderItem={oi}
                  isInPackage={true}
                  currentPackage={pack}
                  packages={packages}
                  onUpdate={onUpdate}
                  onReturn={() => null}
                />
              ))}
            </div>
          </div>
        )
      })}
      <div className={'flex gap-2 pb-1.5 pl-20'}>
        {order.processStatus?.id === 'packing' && (
          <Btn
            onClick={() => addPackage()}
            // isDisabled={isFetching || orderItemsInFirstPackage.length < 2}
            isDisabled={isFetching}
            isLoading={isFetching}
            cssClass={'btn-outline'}
            icon={'plus'}
            children={'Přidat balík'}
            title={'Přidat balík'}
          />
        )}
        {order.processStatus?.id === 'packing' && (
          <Btn
            onClick={() => addPackages()}
            isDisabled={isFetching}
            isLoading={isFetching}
            cssClass={'btn-outline'}
            icon={'plus'}
            children={'Přidat prázdné balíky'}
            title={'Přidat prázdné balíky'}
          />
        )}
      </div>
    </div>
  )
}
