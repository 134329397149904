import { CORE } from './actions'
import { DepotoCore } from 'depoto-core'
import { name as appName } from '../../../package.json'
import {
  Carrier,
  Checkout,
  Company,
  Currency,
  Depot,
  Order,
  OrderItem,
  Package,
  Payment,
  Product,
  Tag,
  User,
} from 'depoto-core/dist/src/entities'

export type PackageUpdate = {
  [packageId: string]: Partial<Package>
}

export type GroupLog = {
  orderId: number
  reservationNumber: number
  record: string
}

export type GroupRecord = {
  date: number
  isVisible: boolean
  log: GroupLog[]
  errors: GroupLog[]
}

export type GroupHistory = {
  [groupId: number]: GroupRecord
}

export type OrderItemForReturn = OrderItem & {
  quantityReturned?: number
}

export type ReturnItem = {
  uuid: string
  amount: number
  depotId: number
  batch: string
  expirationDate: string
  position1: string
  position2: string
  position3: string
  // orderItemId: string | number // todo? fuck this
  orderItem: Partial<OrderItem>
  // productId: string | number // todo? also..
  product?: Partial<Product> // in case of bundles, we need to know which product from bundle..
  note: string
}

export type OrderReturns = {
  [orderId: number]: Array<ReturnItem>
}

export type CoreSliceType = {
  core: DepotoCore
  searchString?: string
  searchType: 'reservationNumber' | 'billNumber' | 'package' | 'box' | 'customer' | 'fulltext'
  itemCount?: number
  isFetching?: boolean
  currentOrder?: Order & { updatedAt: number }
  ordersHistory: Array<Partial<Order>>
  orderUpdates?: Partial<Order>
  packagesUpdates?: PackageUpdate
  groupHistory: GroupHistory
  groupSortProp: 'orderCount' | 'created'
  groupSortDir: 'DESC' | 'ASC'
  groupFilterName?: string
  carriers: Carrier[]
  checkouts: Checkout[]
  company?: Company
  childCompanies: Company[]
  childCompanyId?: number
  countries: any[] // todo
  currencies: Currency[]
  depots: Depot[]
  isStrictDepotFilter: boolean
  autoProcessStatusChange: boolean
  payments: Payment[]
  tags: Tag[]
  selectedTagId?: number
  selectedCarrierId?: string
  selectedCheckoutId?: number | string
  selectedDepotId?: number | string
  selectedCountry?: string
  selectedDate?: Date | number
  orderReturns: OrderReturns
}

export const initialState: CoreSliceType = {
  core: new DepotoCore(appName),
  searchString: '',
  searchType: 'reservationNumber',
  itemCount: 0,
  isFetching: false,
  currentOrder: undefined,
  ordersHistory: [],
  orderUpdates: {},
  packagesUpdates: {},
  groupHistory: {},
  groupSortProp: 'orderCount',
  groupSortDir: 'DESC',
  groupFilterName: undefined,
  carriers: [],
  checkouts: [],
  company: undefined,
  childCompanies: [],
  countries: [],
  currencies: [],
  depots: [],
  isStrictDepotFilter: false,
  autoProcessStatusChange: false,
  payments: [],
  tags: [],
  selectedTagId: undefined,
  selectedCarrierId: undefined,
  selectedCheckoutId: undefined,
  selectedDepotId: undefined,
  selectedCountry: undefined,
  selectedDate: undefined,
  orderReturns: {},
}

export const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case CORE.SET_CORE:
      return {
        ...state,
        core: action.payload.core || undefined,
      }
    case CORE.SET_SEARCH_STRING:
      return {
        ...state,
        searchString: action.payload.searchString || '',
      }
    case CORE.SET_SEARCH_TYPE:
      return {
        ...state,
        searchType: action.payload.searchType || 'reservationNumber',
      }
    case CORE.SET_ITEM_COUNT:
      return {
        ...state,
        itemCount: action.payload.itemCount || 0,
      }
    case CORE.SET_IS_FETCHING:
      return {
        ...state,
        isFetching: action.payload.isFetching || false,
      }
    case CORE.SET_CURRENT_ORDER:
      const currentOrder = { ...action.payload.currentOrder, updatedAt: +new Date() } || undefined
      // console.log('asd', { currentOrder, note: currentOrder.note })
      const ordersHistory = state.ordersHistory || []
      if (currentOrder && !ordersHistory.map(o => o.id)?.includes(currentOrder.id)) {
        ordersHistory.unshift(currentOrder)
      }
      if (ordersHistory?.length > 20) {
        ordersHistory.splice(20)
      }
      return {
        ...state,
        currentOrder,
        ordersHistory,
      }
    case CORE.SET_ORDERS_HISTORY:
      return {
        ...state,
        ordersHistory: action.payload.ordersHistory || [],
      }
    case CORE.SET_ORDER_UPDATES:
      return {
        ...state,
        orderUpdates: action.payload.orderUpdates || {},
      }
    case CORE.SET_PACKAGE_UPDATES:
      return {
        ...state,
        packagesUpdates: action.payload.packagesUpdates || {},
      }
    case CORE.SET_GROUP_HISTORY:
      return {
        ...state,
        groupHistory: action.payload.groupHistory || {},
      }
    case CORE.SET_GROUP_SORT_PROP:
      return {
        ...state,
        groupSortProp: action.payload.groupSortProp || 'orderCount',
      }
    case CORE.SET_GROUP_SORT_DIR:
      return {
        ...state,
        groupSortDir: action.payload.groupSortDir || 'DESC',
      }
    case CORE.SET_GROUP_FILTER_NAME:
      return {
        ...state,
        groupFilterName: action.payload.groupFilterName || undefined,
      }
    case CORE.SET_CARRIERS:
      return {
        ...state,
        carriers: action.payload.carriers || [],
      }
    case CORE.SET_CHECKOUTS:
      return {
        ...state,
        checkouts: action.payload.checkouts || [],
      }
    case CORE.SET_COMPANY:
      return {
        ...state,
        company: action.payload.company || undefined,
      }
    case CORE.SET_CHILD_COMPANIES:
      return {
        ...state,
        childCompanies: action.payload.childCompanies || [],
      }
    case CORE.SET_SELECTED_CHILD_COMPANY:
      return {
        ...state,
        childCompanyId: action.payload.childCompanyId || 0,
      }
    case CORE.SET_COUNTRIES:
      return {
        ...state,
        countries: action.payload.countries || [],
      }
    case CORE.SET_CURRENCIES:
      return {
        ...state,
        currencies: action.payload.currencies || [],
      }
    case CORE.SET_DEPOTS:
      return {
        ...state,
        depots: action.payload.depots || [],
      }
    case CORE.SET_IS_STRICT_DEPOT_FILTER:
      return {
        ...state,
        isStrictDepotFilter: action.payload.isStrictDepotFilter || false,
      }
    case CORE.SET_AUTO_PROCESS_STATUS_CHANGE:
      return {
        ...state,
        autoProcessStatusChange: action.payload.autoProcessStatusChange || false,
      }
    case CORE.SET_PAYMENTS:
      return {
        ...state,
        payments: action.payload.payments || [],
      }
    case CORE.SET_TAGS:
      return {
        ...state,
        tags: action.payload.tags || [],
      }
    case CORE.SET_SELECTED_TAG_ID:
      return {
        ...state,
        selectedTagId: action.payload.selectedTagId || 0,
      }
    case CORE.SET_SELECTED_CARRIER_ID:
      return {
        ...state,
        selectedCarrierId: action.payload.selectedCarrierId || 0,
      }
    case CORE.SET_SELECTED_CHECKOUT_ID:
      return {
        ...state,
        selectedCheckoutId: action.payload.selectedCheckoutId || 0,
      }
    case CORE.SET_SELECTED_DEPOT_ID:
      return {
        ...state,
        selectedDepotId: action.payload.selectedDepotId || 0,
      }
    case CORE.SET_SELECTED_COUNTRY:
      return {
        ...state,
        selectedCountry: action.payload.selectedCountry || undefined,
      }
    case CORE.SET_SELECTED_DATE:
      return {
        ...state,
        selectedDate: action.payload.selectedDate || undefined,
      }
    case CORE.SET_ORDER_RETURNS:
      return {
        ...state,
        orderReturns: action.payload.orderReturns || {},
      }
    default: {
      return state
    }
  }
}
