import { useGlobalStore } from '../store'

import bindActions from '../store/bindActions'
import { CoreReducer, CoreSliceType } from '../store/core'
import { Fn } from 'depoto-core/dist/src/models'
import { setSelectedDate } from '../store/core/actions'

const { actions } = CoreReducer

type CoreHookReturnType = CoreSliceType & {
  setCore: Fn
  setCarriers: Fn
  setCheckouts: Fn
  setSelectedCarrierId: Fn
  setSelectedCheckoutId: Fn
  setSelectedCountry: Fn
  setSelectedDepotId: Fn
  setCountries: Fn
  setDepots: Fn
  setCurrencies: Fn
  setCompany: Fn
  setChildCompanies: Fn
  setSelectedChildCompany: Fn
  setCurrentOrder: Fn
  setOrdersHistory: Fn
  setIsStrictDepotFilter: Fn
  setAutoProcessStatusChange: Fn
  setOrderUpdates: Fn
  setPackageUpdates: Fn
  setGroupHistory: Fn
  setGroupSortProp: Fn
  setGroupSortDir: Fn
  setGroupFilterName: Fn
  setPayments: Fn
  setTags: Fn
  setSelectedTagId: Fn
  setSearchString: Fn
  setSearchType: Fn
  setItemCount: Fn
  setIsFetching: Fn
  setOrderReturns: Fn
  setSelectedDate: Fn
}
export const useCore = (): CoreHookReturnType => {
  const { state, dispatch } = useGlobalStore()
  const { core } = state
  const {
    setCore,
    setCarriers,
    setCheckouts,
    setSelectedCarrierId,
    setSelectedCheckoutId,
    setSelectedCountry,
    setSelectedDepotId,
    setCountries,
    setDepots,
    setCurrencies,
    setCompany,
    setChildCompanies,
    setSelectedChildCompany,
    setCurrentOrder,
    setOrdersHistory,
    setIsStrictDepotFilter,
    setAutoProcessStatusChange,
    setOrderUpdates,
    setPackageUpdates,
    setGroupHistory,
    setGroupSortProp,
    setGroupSortDir,
    setGroupFilterName,
    setPayments,
    setTags,
    setSelectedTagId,
    setSearchString,
    setSearchType,
    setItemCount,
    setIsFetching,
    setOrderReturns,
  } = actions
  const coreActions = bindActions(
    {
      setCore,
      setCarriers,
      setCheckouts,
      setSelectedCarrierId,
      setSelectedCheckoutId,
      setSelectedCountry,
      setSelectedDepotId,
      setCountries,
      setDepots,
      setCurrencies,
      setCompany,
      setChildCompanies,
      setSelectedChildCompany,
      setCurrentOrder,
      setOrdersHistory,
      setIsStrictDepotFilter,
      setAutoProcessStatusChange,
      setOrderUpdates,
      setPackageUpdates,
      setGroupHistory,
      setGroupSortProp,
      setGroupSortDir,
      setGroupFilterName,
      setPayments,
      setTags,
      setSelectedTagId,
      setSearchString,
      setSearchType,
      setItemCount,
      setSelectedDate,
      setIsFetching,
      setOrderReturns,
    },
    dispatch,
  )

  return { ...core, ...coreActions }
}
