import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { EditExpeditionBatchPositionModal } from './EditExpeditionBatchPositionModal'
import { Checkbox } from './Checkbox'
import { ClearanceItem, File as DepotoFile, Order, ProductBundle } from 'depoto-core/dist/src/entities'
import { Fn } from 'depoto-core/dist/src/models'
import { PhotoSwipe } from './PhotoSwipe'
import { useCore } from '../hooks'

type Props = {
  clearanceItem: ClearanceItem
  onUpdate: Fn
}

export const ClearanceItemRow: React.FC<Props> = ({ clearanceItem, onUpdate }) => {
  const { core, isFetching, setIsFetching, currentOrder } = useCore()

  const thumbUrl = clearanceItem.product?.mainImage?.thumbnails?.length
    ? clearanceItem.product?.mainImage.thumbnails.filter(t => t.format === 'w135')[0].url
    : ''

  const imageUrl = clearanceItem.product?.mainImage?.thumbnails?.length
    ? clearanceItem.product?.mainImage.thumbnails.filter(t => t.format === 'w2048')[0].url
    : ''

  const processStatus = currentOrder?.processStatus?.id

  const oiNotes = currentOrder?.items
    ?.filter(oi => oi.clearanceItems?.filter(ci => ci.id === clearanceItem.id).length)
    .map(oi => oi.note)

  const setPicked = async (picked: number) => {
    setIsFetching(true)
    try {
      await core?.services.order.updateClearanceItemFromOrder({ id: clearanceItem.id, picked }, currentOrder!, 'picked')
    } catch (errors) {
      alert(`Chyba:\n${JSON.stringify(errors)}`)
    }
    setIsFetching(false)
    onUpdate()
  }

  const setPacked = async (packed: number) => {
    setIsFetching(true)
    try {
      await core?.services.order.updateClearanceItemFromOrder({ id: clearanceItem.id, packed }, currentOrder!, 'packed')
    } catch (errors) {
      alert(`Chyba:\n${JSON.stringify(errors)}`)
    }
    setIsFetching(false)
    onUpdate()
  }

  return (
    <>
      <div className="grid grid-cols-12 leading-10">
        {/*1. sloupec*/}
        <div
          className={
            clearanceItem.product?.isFragile === false && clearanceItem.product?.isOversize === false
              ? 'grid grid-cols-5 col-span-4 gap-2 pl-20'
              : 'grid grid-cols-4 col-span-3 gap-2 pl-20'
          }>
          <div className="col-end-1 self-center">
            <PhotoSwipe
              alt={''}
              caption={`
                        ${clearanceItem.product ? clearanceItem.product.fullName : ''} <br> 
                        ${clearanceItem.product?.code ? 'Kód: ' + clearanceItem.product.code : ''} <br> 
                        ${clearanceItem.product?.ean ? 'EAN: ' + clearanceItem.product.ean : ''}`}
              galleryImg={imageUrl}
              src={thumbUrl}
              thumbnails={''}
            />
          </div>
          <div
            className={
              clearanceItem.product?.isFragile === false && clearanceItem.product?.isOversize === false
                ? 'col-span-5 self-center font-semibold'
                : 'col-span-4 self-center font-semibold'
            }>
            {clearanceItem.product?.fullName}
          </div>
        </div>
        {/*2. sloupec */}
        {(clearanceItem.product?.isFragile || clearanceItem.product?.isOversize) && (
          <div className={'flex flex-col items-center'}>
            <div>
              <span className={'label-yellow'}>
                <FontAwesomeIcon icon={['far', 'fragile']} size="lg" className={'mr-2'} />
                <span>Křehké</span>
              </span>
            </div>
            <div>
              <span className={'label-purple'}>
                <FontAwesomeIcon icon={['far', 'expand-alt']} size="lg" className={'mr-2'} />
                <span>Nadměrné</span>
              </span>
            </div>
          </div>
        )}
        {/*3. sloupec*/}
        <div className={'self-center whitespace-nowrap overflow-x-auto mr-4'}>{clearanceItem?.product?.code}</div>
        {/*4. sloupec*/}
        <div className={'self-center whitespace-nowrap overflow-x-auto mr-4'}>{clearanceItem?.product?.ean}</div>
        {/*5. sloupec*/}
        <div className={'self-center'}>
          {clearanceItem?.expirationDate.substring(clearanceItem.expirationDate.length - 8) === '00:00:00'
            ? clearanceItem.expirationDate.substring(0, 11)
            : clearanceItem.expirationDate}
        </div>
        {/*6. sloupec*/}
        <div className={'self-center'}>{clearanceItem?.batch}</div>
        {/*7. sloupec*/}
        <div className={'self-center'}>
          <div>{clearanceItem?.position}</div>
        </div>
        {/*8. sloupec*/}
        <div className="place-self-center self-center">
          <div className="square">
            <span className="square-amount">{clearanceItem?.amount}</span>
          </div>
        </div>
        <div className={'place-self-center self-center p-1'}>
          <EditExpeditionBatchPositionModal clearanceItem={clearanceItem} onUpdate={onUpdate} />
        </div>
        {/*9. sloupec*/}
        {processStatus === 'picking' && (
          <div className={'place-self-center self-center'}>
            <Checkbox
              checked={clearanceItem.picked === clearanceItem.amount}
              onChange={() => setPicked(clearanceItem.picked === clearanceItem.amount ? 0 : clearanceItem.amount)}
              disabled={isFetching}
            />
          </div>
        )}
        {processStatus === 'packing' && (
          <div className={'place-self-center self-center'}>
            <Checkbox
              checked={clearanceItem.packed === clearanceItem.amount}
              onChange={() => setPacked(clearanceItem.packed === clearanceItem.amount ? 0 : clearanceItem.amount)}
              disabled={isFetching}
            />
          </div>
        )}
      </div>
      {oiNotes && oiNotes?.length > 0 && oiNotes[0]?.length > 0 && (
        <div className="flex flex-col pl-10 pr-10">
          {oiNotes.map((n, i) => (
            <div key={`${n}-${i}`} className="w-full text-red-600">
              {n}
            </div>
          ))}
        </div>
      )}
    </>
  )
}
