import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Btn } from './Btn'
import { FormInput } from './FormInput'
import { NumberHelper, printTicket, wait } from '../lib'
import { Package } from 'depoto-core/dist/src/entities'
import { Fn } from 'depoto-core/dist/src/models'
import { useCore } from '../hooks'
import { FormSelect } from './FormSelect'
import tippy from 'tippy.js'

type Props = {
  pack: Package
  index: number
  onUpdate: Fn
}
export const PackageDetail: React.FC<Props> = ({ pack, index, onUpdate }) => {
  const [weight, setWeight] = useState(pack.weight || '')
  const [dimensionX, setDimensionX] = useState(pack.dimensionX || '')
  const [dimensionY, setDimensionY] = useState(pack.dimensionY || '')
  const [dimensionZ, setDimensionZ] = useState(pack.dimensionZ || '')
  const { core, currentOrder, isFetching, setIsFetching, packagesUpdates, setPackageUpdates } = useCore()
  const [tariffId, setTariffId] = useState(pack.tariff?.id || 0)
  const [isListeningForScale, setIsListeningForScale] = useState<boolean>(false)
  const tariffs = [
    { value: 0, label: 'nezvoleno' },
    ...(pack.carrier?.tariffs.map(t => ({ value: t.id, label: t.name })) || []),
  ]
  // useUnload((e: Event | any) => {
  //   e.preventDefault()
  //   e.returnValue = 'Máte neuložené změny. Opravdu si přejete odejít?'
  // });
  // useEffect(() => {
  //   window.addEventListener("beforeunload", function (e) {
  //     if (packagesUpdates && Object.keys(packagesUpdates).length > 0) {
  //       const confirmationMessage = 'Máte neuložené změny. Opravdu si přejete odejít?';
  //       (e || window.event).returnValue = confirmationMessage; //Gecko + IE
  //       return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
  //     }
  //   });
  // })

  const readWeight = () => {
    if (isListeningForScale) {
      setIsListeningForScale(false)
      console.log('scale listen end - manual')
    } else {
      setIsListeningForScale(true)
      console.log('scale listen start')
      if (window.weightHistory?.length) {
        console.log('weight history: ', window.weightHistory)
        console.log('last weight: ', window.weightHistory[window.weightHistory.length - 1])
        updateWeight(window.weightHistory[window.weightHistory.length - 1])
        console.log('scale listen end - after update event')
        setIsListeningForScale(false)
        if (window.weightHistory.length > 100) {
          console.log('clear weight history')
          window.weightHistory = []
        }
      }
    }
  }

  const updateWeight = async (val: string) => {
    if (pack.weightRequired && (val === undefined || val === '')) {
      alert('Hmotnost je povinná!')
      return
    }
    val = val?.replaceAll(',', '.')
    setWeight(val)
    const packageUpdate = {
      ...(packagesUpdates && typeof packagesUpdates[pack.id] !== 'undefined' ? packagesUpdates[pack.id] : {}),
    }
    packageUpdate.weight = isNaN(Number(val)) ? undefined : Number(val)
    if (pack.weight !== packageUpdate.weight && !!packageUpdate.weight) {
      setPackageUpdates({ ...packagesUpdates, [pack.id]: packageUpdate })
    }
  }

  const updateDimension = async (dimension: 'X' | 'Y' | 'Z', val: string) => {
    if (pack.dimensionsRequired && (val === undefined || val === '')) {
      alert('Rozměr je povinný!')
      return
    }
    val = val?.replaceAll(',', '.')
    const numVal = isNaN(Number(val)) ? 0 : Number(val)
    const prop: 'dimensionX' | 'dimensionY' | 'dimensionZ' = `dimension${dimension}`
    dimension === 'X' ? setDimensionX(val) : dimension === 'Y' ? setDimensionY(val) : setDimensionZ(val)
    const packageUpdate = {
      ...(packagesUpdates && typeof packagesUpdates[pack.id] !== 'undefined' ? packagesUpdates[pack.id] : {}),
    }
    packageUpdate[prop] = numVal === 0 ? undefined : numVal
    if (pack[prop] !== packageUpdate[prop]) {
      setPackageUpdates({ ...packagesUpdates, [pack.id]: packageUpdate })
    }
  }

  const updateTariffId = async (id: number) => {
    setTariffId(id)
    setPackageUpdates({ ...packagesUpdates, [pack.id]: { tariff: { id: Number(id) } } })
  }

  const resetPackage = async () => {
    setIsFetching(true)
    try {
      await core?.services.pack.reset({ id: pack.id })
    } catch (errors) {
      alert(errors.map((e: any) => e.message).join('\n'))
    }
    setIsFetching(false)
    await wait()
    onUpdate()
  }

  const removePackage = async () => {
    setIsFetching(true)
    if (pack.items.length > 0 && currentOrder?.packages && currentOrder.packages.length > 1) {
      try {
        await core?.services.pack.updatePart({
          id: currentOrder.packages[0].id,
          items: [...currentOrder.packages[0].items, ...pack.items],
        })
      } catch (errors) {
        alert(errors.map((e: any) => e.message).join('\n'))
      }
    }
    try {
      await core?.services.pack.delete({ id: pack.id })
    } catch (errors) {
      alert(errors.map((e: any) => e.message).join('\n'))
    }
    setIsFetching(false)
    await wait()
    onUpdate()
  }

  const sendPackage = async () => {
    if (currentOrder?.carrier?.requiredShippingAddress && !currentOrder?.shippingAddress) {
      alert('Není nastavena doručovací adresa!')
      return
    }
    setIsFetching(true)
    try {
      await core?.services.pack.send({ id: pack.id })
    } catch (errors) {
      alert(errors.map((e: any) => e.message).join('\n'))
    }
    setIsFetching(false)
    await wait()
    onUpdate()
  }

  const printPackageTicket = async () => {
    setIsFetching(true)
    await printTicket(pack)
    await wait()
    setIsFetching(false)
    onUpdate()
  }

  useEffect(() => {
    tippy('[data-tippy-content]')
  }, [])

  return (
    <div className={'p-main flex justify-between'}>
      <div className={'flex gap-4'}>
        <div className={'flex items-center gap-2'}>
          <FontAwesomeIcon icon={['far', 'box-open']} className={'text-black'} size={'lg'} />
          <div className={'text-black font-semibold'}>
            Balík {pack.code} [{index}]
          </div>
        </div>
        <div className={'flex items-center gap-2'}>
          <div className={'text-shadow-grey font-semibold'}>{pack.carrier?.name}</div>
          {currentOrder?.packages && currentOrder.packages.length > 1 && (
            <Btn
              cssClass={'btn-danger btn-circle'}
              icon={'trash-alt'}
              isLoading={isFetching}
              title={'Smazat'}
              onClick={removePackage}
            />
          )}
        </div>
      </div>
      <div className={'flex gap-4 justify-between'}>
        <div className={'flex items-center gap-2'}>
          <div className={'flex items-center gap-2'}>
            <div className={'flex flex-col'}>
              <div className={'text-black font-bold'}>Tarif</div>
            </div>
            <FormSelect cssClass={'flex-1'} value={tariffId} options={tariffs} onChange={updateTariffId} />
            <div className={'flex flex-col'}>
              <div className={'text-black font-bold'}>Hmotnost</div>
              <div className={'text-shadow-grey font-medium self-end'}>kg</div>
            </div>
            <FormInput
              cssClass={'w-20'}
              type={'text'}
              // value={`${NumberHelper.toString(weight)}`}
              value={`${weight}`}
              // onChange={val => setWeight(val)} // todo this shit
              onChange={val => {
                // setWeight(val)
                updateWeight(`${val}`)
                // updateWeight(`${weight}`)
              }}
              onSubmit={() => updateWeight(`${weight}`)}
              // onBlur={val => val !== weight ? updateWeight(`${weight}`) : null}
            />
            {'_depoto' in window ? (
              <FontAwesomeIcon
                icon={['far', 'weight-scale']}
                size="lg"
                color={isListeningForScale ? '#a7130e' : '#000'}
                style={{ marginLeft: '-30px', marginRight: '5px' }}
                data-tippy-content={isListeningForScale ? 'Probíhá načítání hmotnosti' : 'Načíst hmotnost z váhy'}
                onClick={readWeight}
              />
            ) : null}
          </div>
          <div className={'flex items-center gap-2'}>
            <div className={'flex flex-col'}>
              <div className={'text-black font-bold'}>Šířka</div>
              <div className={'text-shadow-grey font-medium self-end'}>cm</div>
            </div>
            <FormInput
              cssClass={'w-20'}
              type={'text'}
              value={`${dimensionX}`}
              // onChange={val => setDimensionX(val)} // todo
              onChange={val => updateDimension('X', `${val}`)}
              onSubmit={() => updateDimension('X', `${dimensionX}`)}
              onBlur={val => (val !== dimensionX ? updateDimension('X', `${dimensionX}`) : null)}
            />
          </div>
          <div className={'flex items-center gap-2'}>
            <div className={'flex flex-col'}>
              <div className={'text-black font-bold'}>Výška </div>
              <div className={'text-shadow-grey font-medium self-end'}>cm</div>
            </div>
            <FormInput
              cssClass={'w-20'}
              type={'text'}
              value={`${dimensionY}`}
              // onChange={val => setDimensionY(val)}
              onChange={val => updateDimension('Y', `${val}`)}
              onSubmit={() => updateDimension('Y', `${dimensionY}`)}
              onBlur={val => (val !== dimensionY ? updateDimension('Y', `${dimensionY}`) : null)}
            />
          </div>
          <div className={'flex items-center gap-2'}>
            <div className={'flex flex-col'}>
              <div className={'text-black font-bold'}>Hloubka</div>
              <div className={'text-shadow-grey font-medium self-end'}>cm</div>
            </div>
            <FormInput
              cssClass={'w-20'}
              type={'text'}
              value={`${dimensionZ}`}
              // onChange={val => setDimensionZ(val)}
              onChange={val => updateDimension('Z', `${val}`)}
              onSubmit={() => updateDimension('Z', `${dimensionZ}`)}
              onBlur={val => (val !== dimensionZ ? updateDimension('Z', `${dimensionZ}`) : null)}
            />
          </div>
        </div>
        <div className={'flex items-center gap-2'}>
          <Btn
            cssClass={'btn-outline-red border-danger'}
            icon={'arrow-rotate-right'}
            children={'Resetovat'}
            title={'Resetovat'}
            isLoading={isFetching}
            isDisabled={isFetching}
            onClick={resetPackage}
          />
          {!pack.sent && (
            <Btn
              cssClass={'btn-primary'}
              icon={'send'}
              children={'Poslat balik'}
              title={'Poslat balik'}
              isLoading={isFetching}
              isDisabled={isFetching}
              onClick={sendPackage}
            />
          )}
          {pack.sent && (
            <Btn
              cssClass={'btn-primary'}
              icon={'print'}
              children={'Vytisknout štítek'}
              title={'Vytisknout štítek'}
              isLoading={isFetching}
              isDisabled={isFetching}
              onClick={printPackageTicket}
            />
          )}
        </div>
      </div>
    </div>
  )
}
