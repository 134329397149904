import { OAuthSession } from 'depoto-core/dist/src/models'
import { DepotoCore } from 'depoto-core'
import {
  Carrier,
  Checkout,
  Company,
  Currency,
  Depot,
  Order,
  OrderItem,
  Package,
  Payment,
  Tag,
  User,
} from 'depoto-core/dist/src/entities'
import { wait } from '../../lib'
import { GroupHistory, OrderReturns } from './reducer'

export enum CORE {
  SET_CORE = 'CORE_SET_CORE',
  SET_SEARCH_STRING = 'CORE_SET_SEARCH_STRING',
  SET_SEARCH_TYPE = 'CORE_SET_SEARCH_TYPE',
  SET_ITEM_COUNT = 'CORE_SET_ITEM_COUNT',
  SET_IS_FETCHING = 'CORE_SET_IS_FETCHING',
  SET_CURRENT_ORDER = 'CORE_SET_CURRENT_ORDER',
  SET_ORDERS_HISTORY = 'CORE_SET_ORDERS_HISTORY',
  SET_ORDER_UPDATES = 'CORE_SET_ORDER_UPDATES',
  SET_PACKAGE_UPDATES = 'CORE_SET_PACKAGE_UPDATES',
  SET_GROUP_HISTORY = 'CORE_SET_GROUP_HISTORY',
  SET_GROUP_SORT_PROP = 'CORE_SET_GROUP_SORT_PROP',
  SET_GROUP_SORT_DIR = 'CORE_SET_GROUP_SORT_DIR',
  SET_GROUP_FILTER_NAME = 'CORE_SET_GROUP_FILTER_NAME',
  SET_CARRIERS = 'CORE_SET_CARRIERS',
  SET_CHECKOUTS = 'CORE_SET_CHECKOUTS',
  SET_COMPANY = 'CORE_SET_COMPANY',
  SET_CHILD_COMPANIES = 'CORE_SET_CHILD_COMPANIES',
  SET_SELECTED_CHILD_COMPANY = 'CORE_SET_SELECTED_CHILD_COMPANY',
  SET_COUNTRIES = 'CORE_SET_COUNTRIES',
  SET_CURRENCIES = 'CORE_SET_CURRENCIES',
  SET_DEPOTS = 'CORE_SET_DEPOTS',
  SET_IS_STRICT_DEPOT_FILTER = 'CORE_SET_IS_STRICT_DEPOT_FILTER',
  SET_AUTO_PROCESS_STATUS_CHANGE = 'CORE_SET_AUTO_PROCESS_STATUS_CHANGE',
  SET_PAYMENTS = 'CORE_SET_PAYMENTS',
  SET_TAGS = 'CORE_SET_TAGS',
  SET_SELECTED_TAG_ID = 'CORE_SET_SELECTED_TAG_ID',
  SET_SELECTED_CARRIER_ID = 'CORE_SET_SELECTED_CARRIER_ID',
  SET_SELECTED_CHECKOUT_ID = 'CORE_SET_SELECTED_CHECKOUT_ID',
  SET_SELECTED_DEPOT_ID = 'CORE_SET_SELECTED_DEPOT_ID',
  SET_SELECTED_COUNTRY = 'CORE_SET_SELECTED_COUNTRY',
  SET_SELECTED_DATE = 'CORE_SET_SELECTED_DATE',
  SET_ORDER_RETURNS = 'CORE_SET_ORDER_RETURNS',
}

export const setCore = (core: DepotoCore) => ({
  type: CORE.SET_CORE,
  payload: { core },
})

export const setSearchString = (searchString: string) => ({
  type: CORE.SET_SEARCH_STRING,
  payload: { searchString },
})

export const setSearchType = (searchType: string) => ({
  type: CORE.SET_SEARCH_TYPE,
  payload: { searchType },
})

export const setItemCount = (itemCount: number) => ({
  type: CORE.SET_ITEM_COUNT,
  payload: { itemCount },
})

export const setIsFetching = (isFetching: boolean) => ({
  type: CORE.SET_IS_FETCHING,
  payload: { isFetching },
})

export const setCurrentOrder = (currentOrder: Partial<Order>) => ({
  type: CORE.SET_CURRENT_ORDER,
  payload: { currentOrder },
})

export const setOrdersHistory = (ordersHistory: Array<Partial<Order>>) => ({
  type: CORE.SET_ORDERS_HISTORY,
  payload: { ordersHistory },
})

export const setOrderUpdates = (orderUpdates: Partial<Order>) => ({
  type: CORE.SET_ORDER_UPDATES,
  payload: { orderUpdates },
})

export const setPackageUpdates = (packagesUpdates: Partial<Package>) => ({
  type: CORE.SET_PACKAGE_UPDATES,
  payload: { packagesUpdates },
})

export const setGroupHistory = (groupHistory: GroupHistory) => ({
  type: CORE.SET_GROUP_HISTORY,
  payload: { groupHistory },
})

export const setGroupSortProp = (groupSortProp: 'orderCount' | 'created') => ({
  type: CORE.SET_GROUP_SORT_PROP,
  payload: { groupSortProp },
})

export const setGroupSortDir = (groupSortDir: 'ASC' | 'DESC') => ({
  type: CORE.SET_GROUP_SORT_DIR,
  payload: { groupSortDir },
})

export const setGroupFilterName = (groupFilterName?: string) => ({
  type: CORE.SET_GROUP_FILTER_NAME,
  payload: { groupFilterName },
})

export const setCarriers = (carriers: Array<Partial<Carrier>>) => ({
  type: CORE.SET_CARRIERS,
  payload: { carriers },
})

export const setCheckouts = (checkouts: Array<Partial<Checkout>>) => ({
  type: CORE.SET_CHECKOUTS,
  payload: { checkouts },
})

export const setCompany = (company: Partial<Company>) => ({
  type: CORE.SET_COMPANY,
  payload: { company },
})

export const setChildCompanies = (childCompanies: Array<Partial<Company>>) => ({
  type: CORE.SET_CHILD_COMPANIES,
  payload: { childCompanies },
})

export const setSelectedChildCompany = (childCompanyId: number) => ({
  type: CORE.SET_SELECTED_CHILD_COMPANY,
  payload: { childCompanyId },
})

export const setCountries = (countries: Array<any>) => ({
  type: CORE.SET_COUNTRIES,
  payload: { countries },
})

export const setCurrencies = (currencies: Array<Partial<Currency>>) => ({
  type: CORE.SET_CURRENCIES,
  payload: { currencies },
})

export const setDepots = (depots: Array<Partial<Depot>>) => ({
  type: CORE.SET_DEPOTS,
  payload: { depots },
})

export const setIsStrictDepotFilter = (isStrictDepotFilter: boolean) => ({
  type: CORE.SET_IS_STRICT_DEPOT_FILTER,
  payload: { isStrictDepotFilter },
})

export const setAutoProcessStatusChange = (autoProcessStatusChange: boolean) => ({
  type: CORE.SET_AUTO_PROCESS_STATUS_CHANGE,
  payload: { autoProcessStatusChange },
})

export const setPayments = (payments: Array<Partial<Payment>>) => ({
  type: CORE.SET_PAYMENTS,
  payload: { payments },
})

export const setTags = (tags: Array<Partial<Tag>>) => ({
  type: CORE.SET_TAGS,
  payload: { tags },
})

export const setSelectedTagId = (selectedTagId: number | undefined) => ({
  type: CORE.SET_SELECTED_TAG_ID,
  payload: { selectedTagId },
})

export const setSelectedCarrierId = (selectedCarrierId: string | undefined) => ({
  type: CORE.SET_SELECTED_CARRIER_ID,
  payload: { selectedCarrierId },
})

export const setSelectedCheckoutId = (selectedCheckoutId: number | undefined) => ({
  type: CORE.SET_SELECTED_CHECKOUT_ID,
  payload: { selectedCheckoutId },
})

export const setSelectedDepotId = (selectedDepotId: number | undefined) => ({
  type: CORE.SET_SELECTED_DEPOT_ID,
  payload: { selectedDepotId },
})

export const setSelectedCountry = (selectedCountry: string | undefined) => ({
  type: CORE.SET_SELECTED_COUNTRY,
  payload: { selectedCountry },
})

export const setSelectedDate = (selectedDate: Date | number) => ({
  type: CORE.SET_SELECTED_DATE,
  payload: { selectedDate },
})

export const setOrderReturns = (orderReturns: OrderReturns) => ({
  type: CORE.SET_ORDER_RETURNS,
  payload: { orderReturns },
})
