import React, { useEffect, useState } from 'react'
import { Transition } from '@headlessui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dropdown } from './Dropdown'
import {
  getHeaderTitle,
  Schema,
  setSearchParams,
  strings,
  translate,
  updateOrder,
  updatePackages,
  useSearchParams,
} from '../lib'
import { useCore } from '../hooks'
import { Order } from 'depoto-core/dist/src/entities'
import { Link, useHistory, useLocation, useParams } from 'react-router-dom'
import { FormInput } from './FormInput'
import { Btn } from './Btn'
import { IconName } from '@fortawesome/fontawesome-svg-core'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

type Props = {
  activeProcessStatus?: string
  isMainHeader: boolean
}
export const Header: React.FC<Props> = ({ isMainHeader, activeProcessStatus }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [openTab, setOpenTab] = useState<number>(1)
  const [searchIcon, setSearchIcon] = useState<IconName>('search')
  const [groupName, setGroupName] = useState<string>('')
  const {
    core,
    currentOrder,
    setCurrentOrder,
    ordersHistory,
    groupSortProp,
    setGroupSortProp,
    groupSortDir,
    setGroupSortDir,
    groupFilterName,
    setGroupFilterName,
    itemCount,
    isFetching,
    setIsFetching,
    isStrictDepotFilter,
    searchString,
    setSearchString,
    searchType,
    setSearchType,
    depots,
    carriers,
    checkouts,
    countries,
    childCompanies,
    childCompanyId,
    setSelectedChildCompany,
    selectedDepotId,
    setSelectedDepotId,
    selectedCheckoutId,
    setSelectedCheckoutId,
    selectedCarrierId,
    setSelectedCarrierId,
    selectedCountry,
    setSelectedCountry,
    orderUpdates,
    setOrderUpdates,
    packagesUpdates,
    setPackageUpdates,
    selectedDate,
    setSelectedDate,
    tags,
    selectedTagId,
    setSelectedTagId,
  } = useCore()
  const history = useHistory()
  const { pathname } = useLocation()
  const [splitLocation, setSplitLocation] = useState<string[]>(pathname.split('/'))
  const [headerTitle, setHeaderTitle] = useState<string>(getHeaderTitle({ splitLocation, currentOrder }))
  const [datepickerOpen, setDatepickerOpen] = useState<boolean>(false)
  const datePickerRef = React.createRef<DatePicker>()
  // const { url } = useRouteMatch()
  const pathParts = window.location.pathname.split('/')
  let url = '/' // only used on OrderDetail
  if (pathParts.length > 2 && pathParts[1] === 'order') {
    url = `/order/${pathParts[2]}`
  }
  const { searchString: urlSearchString }: any = useParams()
  const { carrierId, checkoutId, countryId, depotId }: any = useSearchParams()
  const searchTypes = [
    { id: 'reservationNumber', name: 'Číslo rezervace' },
    { id: 'billNumber', name: 'Číslo objednávky' },
    { id: 'package', name: 'Balík' },
    { id: 'box', name: 'Přepravka' },
    { id: 'customer', name: 'Zákazník' },
    { id: 'fulltext', name: 'Fulltext' },
  ]

  const isOnGroupsView = window.location.href?.includes('/groups')

  useEffect(() => {
    // document.addEventListener('updateOrderDetails', e => {
    //   console.log('updateOrderDetails event')
    //   onSaveBtnClick()
    // })
    if (window.location.pathname.includes('dashboard')) {
      setTimeout(() => {
        setSelectedDepotId(depotId && depotId !== 'all' ? Number(depotId) : undefined)
        setSelectedCheckoutId(checkoutId && checkoutId !== 'all' ? Number(checkoutId) : undefined)
        setSelectedCarrierId(carrierId && carrierId !== 'all' ? carrierId : undefined)
        setSelectedCountry(countryId && countryId !== 'all' ? countryId : undefined)
      }, 200)
    }
  }, [])
  // todo batch sending packs -. errors thrown in console - to alert
  const filteredTags = tags.filter(filter => {
    if (activeProcessStatus === 'groups') {
      return filter.type === 'orderGroup'
    } else {
      return filter.type === 'order'
    }
  })
  useEffect(() => {
    if (activeProcessStatus === 'groups') {
      const tag = tags.find(t => t.externalId === 'same')
      tag && setSelectedTagId(tag.id)
    }
  }, [activeProcessStatus])

  const hasFragiles = currentOrder?.items?.filter(oi => oi.product?.isFragile).length! > 0
  const hasOversized = currentOrder?.items?.filter(oi => oi.product?.isOversize).length! > 0

  useEffect(() => {
    const splitLoc = pathname.split('/')
    const orderStatusAndNumber =
      currentOrder && currentOrder.processStatus?.name
        ? `${currentOrder.processStatus?.name} | ${currentOrder.reservationNumber}`
        : ''
    setSplitLocation(splitLoc)
    const hTitle =
      splitLoc[1] === 'dashboard'
        ? translate(getHeaderTitle({ splitLocation: splitLoc, currentOrder }))
        : orderStatusAndNumber
    setHeaderTitle(hTitle)
  }, [pathname, currentOrder])

  useEffect(() => {
    switch (searchType) {
      case 'reservationNumber':
        setSearchIcon('list-alt')
        break
      case 'billNumber':
        setSearchIcon('list-tree')
        break
      case 'package':
        setSearchIcon('envelope')
        break
      case 'box':
        setSearchIcon('box')
        break
      case 'customer':
        setSearchIcon('user')
        break
      case 'fulltext':
        setSearchIcon('search')
        break
    }
  }, [searchType])

  const doSearch = (
    nextFilters: {
      carrierId?: string
      checkoutId?: number
      countryId?: string
      depotId?: number
      processStatusChange?: string
    } = {},
  ) => {
    if (searchString && searchString.length > 0) {
      history.push(`/search/${searchString}`)
    } else if (nextFilters && Object.keys(nextFilters).length > 0) {
      if (nextFilters.carrierId) {
        setSelectedCarrierId(nextFilters.carrierId)
      }
      if (nextFilters.checkoutId) {
        setSelectedCheckoutId(nextFilters.checkoutId)
      }
      if (nextFilters.countryId) {
        setSelectedCountry(nextFilters.countryId)
      }
      if (nextFilters.depotId) {
        setSelectedDepotId(nextFilters.depotId)
      }
      history.push(`/dashboard/${activeProcessStatus || 'all'}`)
      setSearchParams(nextFilters)
    } else {
      history.push(activeProcessStatus ? `/dashboard/${activeProcessStatus}` : '/dashboard')
    }
    // console.log('header dosearch', {nextFilters, searchString})
  }

  if (window.location.pathname.includes('order') && window.location.pathname.length > 6) {
    let nextOpenTab = 1
    switch (window.location.pathname.replace(url, '')) {
      case '/items':
      default:
        nextOpenTab = 1
        break
      case '/shipping':
        nextOpenTab = 2
        break
      case '/notes':
        nextOpenTab = 3
        break
      case '/states':
        nextOpenTab = 4
        break
      case '/files':
        nextOpenTab = 5
        break
    }
    if (openTab !== nextOpenTab) {
      setOpenTab(nextOpenTab)
    }
  }

  const onSaveBtnClick = async () => {
    console.log('update from header', { packagesUpdates, orderUpdates })
    if (orderUpdates && Object.keys(orderUpdates).length > 0) {
      await updateOrder(orderUpdates, setOrderUpdates, currentOrder!, core, getOrder)
    }
    if (packagesUpdates && Object.keys(packagesUpdates).length > 0) {
      await updatePackages(packagesUpdates, setPackageUpdates, core, getOrder)
    }
  }

  const getOrder = async () => {
    setIsFetching(true)
    const res = await core?.services.order.getById(currentOrder?.id || 0, Schema.order.detail)
    if (res && res.id > 0) {
      const o = new Order(res)
      setIsFetching(false)
      setCurrentOrder(o)
    } else {
      setIsFetching(false)
    }
  }

  const navigateToOrder = (orderId: number) => {
    history.push(`/order/${orderId}`)
  }

  const clearFilters = () => {
    setSelectedChildCompany(undefined)
    setSelectedDepotId(undefined)
    setSelectedCheckoutId(undefined)
    setSelectedCarrierId(undefined)
    setSelectedCountry(undefined)
    setSelectedTagId(undefined)
    setSelectedDate(undefined)
    setSearchString('')
    setSearchType('reservationNumber')
  }

  return (
    <nav className="bg-white border-b border-cool-grey flex justify-between items-center min-h-16 px-3">
      {/*<div className="grid grid-cols-2 place-content-center divide-x divide-cool-grey">*/}
      <div className="flex flex-row divide-x divide-cool-grey">
        {!isMainHeader && (
          <Btn
            // onClick={() => history.push(`/dashboard/${activeProcessStatus || ''}`)} // was there any reason to do this?
            onClick={() => history.goBack()}
            isDisabled={isFetching}
            isLoading={isFetching}
            cssClass={'btn-outline btn-sm btn-header border-light-grey mr-6'}
            children={'Zpět'}
            title={'Zpět'}
            icon={'arrow-left'}
          />
        )}
        <div className="text-center font-bold text-xl px-6">{headerTitle || strings.appName}</div>
        {!!currentOrder &&
        (window.location.pathname?.includes('order') || window.location.pathname?.includes('history')) ? null : (
          <div className="text-center font-bold text-dark-grey text-xl px-6">
            Celkem: {isFetching ? <span className="animate-pulse">...</span> : itemCount || 0}
          </div>
        )}
        {window.location.pathname?.includes('history') ? (
          <div className="text-center font-bold text-dark-grey text-xl px-6">Historie</div>
        ) : null}
      </div>
      {!isMainHeader && (
        <div className={'flex gap-8 items-center py-2.5'}>
          <div className={'flex items-center gap-3 flex-wrap'}>
            {!currentOrder?.isPaid && (
              <div>
                <span className={'label-red'}>
                  <FontAwesomeIcon icon={['far', 'hourglass']} size="lg" className={'mr-2'} />
                  <span>Neuhrazeno</span>
                </span>
              </div>
            )}
            {hasFragiles && (
              <div>
                <span className={'label-yellow'}>
                  <FontAwesomeIcon icon={['far', 'fragile']} size="lg" className={'mr-2'} />
                  <span>Křehké</span>
                </span>
              </div>
            )}
            {hasOversized && (
              <div>
                <span className={'label-purple'}>
                  <FontAwesomeIcon icon={['far', 'expand-alt']} size="lg" className={'mr-2'} />
                  <span>Nadměrné</span>
                </span>
              </div>
            )}
            {(currentOrder?.tags || []).map(tag => (
              <div key={tag.id}>
                <span className={'label-blue'} style={{ color: tag.color }}>
                  <FontAwesomeIcon icon={['far', 'tag']} size="lg" className={'mr-2'} />
                  <span>{tag.name}</span>
                </span>
              </div>
            ))}
          </div>

          <ul className="flex items-center gap-4" role="tablist">
            <li className="text-center">
              <Link
                to={`${url}/items`}
                className={
                  'text-sm font-semibold px-5 py-3 rounded block ' +
                  (openTab === 1 ? 'bg-secondary-blue text-primary-blue' : 'bg-white text-black')
                }
                onClick={() => {
                  setOpenTab(1)
                }}
                data-toggle="tab"
                role="tablist">
                Položky
              </Link>
            </li>
            <li className="text-center">
              <Link
                to={`${url}/shipping`}
                className={
                  'text-sm font-semibold px-5 py-3 rounded block ' +
                  (openTab === 2 ? 'bg-secondary-blue text-primary-blue' : 'bg-white text-black')
                }
                onClick={() => {
                  setOpenTab(2)
                }}
                data-toggle="tab"
                role="tablist">
                Doprava
              </Link>
            </li>
            <li className="text-center">
              <Link
                to={`${url}/notes`}
                className={
                  'text-sm font-semibold px-5 py-3 rounded block ' +
                  (openTab === 3 ? 'bg-secondary-blue text-primary-blue' : 'bg-white text-black')
                }
                onClick={() => {
                  setOpenTab(3)
                }}
                data-toggle="tab"
                role="tablist">
                Poznámky
              </Link>
            </li>
            <li className="text-center">
              <Link
                to={`${url}/states`}
                className={
                  'text-sm font-semibold px-5 py-3 rounded block ' +
                  (openTab === 4 ? 'bg-secondary-blue text-primary-blue' : 'bg-white text-black')
                }
                onClick={() => {
                  setOpenTab(4)
                }}
                data-toggle="tab"
                role="tablist">
                Informace
              </Link>
            </li>
            <li className="text-center">
              <Link
                to={`${url}/files`}
                className={
                  'text-sm font-semibold px-5 py-3 rounded block ' +
                  (openTab === 5 ? 'bg-secondary-blue text-primary-blue' : 'bg-white text-black')
                }
                onClick={() => {
                  setOpenTab(5)
                }}
                data-toggle="tab"
                role="tablist">
                Soubory
              </Link>
            </li>
          </ul>
          <Btn
            onClick={() => onSaveBtnClick()}
            isDisabled={isFetching || !(orderUpdates || packagesUpdates)}
            isLoading={isFetching}
            cssClass={'btn btn-primary btn-header btn-sm mr-2'}
            children={'Uložit'}
            title={'Uložit'}
            icon={'check'}
          />
        </div>
      )}

      {isMainHeader && (
        <div className="flex justify-end">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <div className="hidden md:block">
                <div className="flex items-center gap-4">
                  {isOnGroupsView && (
                    <div className="flex items-center gap-2">
                      <FontAwesomeIcon icon={['far', 'list-squares']} className="text-primary-blue self-center" />
                      <Dropdown
                        selectedId={groupSortProp}
                        options={[
                          { id: 'orderCount', name: 'Počet objednávek' },
                          { id: 'created', name: 'Datum vytvoření' },
                        ]}
                        hasAllOption={false}
                        title={'Řadit podle'}
                        isTitlePrefix={true}
                        onChange={val => setGroupSortProp(val)}
                      />
                    </div>
                  )}
                  {isOnGroupsView && (
                    <div className="flex items-center gap-2">
                      <FontAwesomeIcon
                        icon={['far', groupSortDir === 'ASC' ? 'chevron-circle-up' : 'chevron-circle-down']}
                        className="text-primary-blue self-center"
                      />
                      <Dropdown
                        selectedId={groupSortDir}
                        options={[
                          { id: 'ASC', name: 'Vzestupně' },
                          { id: 'DESC', name: 'Sestupně' },
                        ]}
                        hasAllOption={false}
                        title={'Směr'}
                        isTitlePrefix={true}
                        onChange={val => setGroupSortDir(val)}
                      />
                    </div>
                  )}
                  {!isOnGroupsView && childCompanies?.length > 0 && (
                    <div className="flex items-center gap-2">
                      <FontAwesomeIcon icon={['far', 'house-building']} className="text-primary-blue self-center" />
                      <Dropdown
                        selectedId={childCompanyId}
                        options={childCompanies}
                        onChange={val => setSelectedChildCompany(val)}
                      />
                    </div>
                  )}
                  {/*todo: tmp bgcolor, proper solution here!*/}
                  {activeProcessStatus === 'dispatched' && (
                    <div
                      className="flex items-center max-w-xs"
                      style={{ backgroundColor: isStrictDepotFilter ? '#a1fa7a' : 'inherit' }}
                      onClick={() => setDatepickerOpen(true)}>
                      <FontAwesomeIcon icon={['far', 'calendar-days']} className="text-primary-blue self-center mr-2" />
                      <div style={{ display: datepickerOpen || !!selectedDate ? 'block' : 'none' }}>
                        <DatePicker
                          selected={(selectedDate || new Date()) as Date}
                          onChange={val => {
                            doSearch({ processStatusChange: val?.toISOString() || undefined })
                            setSelectedDate(val)
                          }}
                          open={datepickerOpen}
                          onClickOutside={() => setDatepickerOpen(false)}>
                          <Btn
                            children={'Resetovat'}
                            cssClass={'btn-secondary btn-xs gap-2 w-full'}
                            icon={'x'}
                            title={'Resetovat'}
                            onClick={() => {
                              doSearch({ processStatusChange: undefined })
                              setSelectedDate(undefined)
                            }}
                          />
                        </DatePicker>
                      </div>
                      {!datepickerOpen && !selectedDate && (
                        <span>
                          Vše{' '}
                          <FontAwesomeIcon
                            icon={['far', 'chevron-right']}
                            size="lg"
                            className={'mx-2 text-primary-blue transform rotate-90'}
                          />
                        </span>
                      )}
                    </div>
                  )}
                  {!isOnGroupsView && (
                    <div
                      className="flex items-center gap-2"
                      style={{ backgroundColor: isStrictDepotFilter ? '#a1fa7a' : 'inherit' }}>
                      <FontAwesomeIcon icon={['far', 'warehouse-alt']} className="text-primary-blue self-center" />
                      <Dropdown
                        selectedId={selectedDepotId}
                        options={depots}
                        onChange={val => doSearch({ depotId: val || 'all' })}
                      />
                    </div>
                  )}
                  {!isOnGroupsView && (
                    <div className="flex items-center gap-2">
                      <FontAwesomeIcon icon={['far', 'cash-register']} className="text-primary-blue self-center" />
                      <Dropdown
                        selectedId={selectedCheckoutId}
                        options={checkouts}
                        onChange={val => doSearch({ checkoutId: val || 'all' })}
                      />
                    </div>
                  )}
                  {!isOnGroupsView && (
                    <div className="flex items-center gap-2">
                      <FontAwesomeIcon icon={['far', 'truck']} className="text-primary-blue self-center" />
                      <Dropdown
                        selectedId={selectedCarrierId}
                        options={carriers}
                        onChange={val => doSearch({ carrierId: val || 'all' })}
                      />
                    </div>
                  )}
                  {!isOnGroupsView && (
                    <div className="flex items-center gap-2">
                      <FontAwesomeIcon icon={['far', 'globe']} className="text-primary-blue self-center" />
                      <Dropdown
                        selectedId={selectedCountry}
                        options={countries}
                        onChange={val => doSearch({ countryId: val || 'all' })}
                      />
                    </div>
                  )}
                  {tags?.length > 0 && (
                    <div className="flex items-center gap-2">
                      <FontAwesomeIcon icon={['far', 'tag']} className="text-primary-blue self-center" />
                      <Dropdown
                        selectedId={selectedTagId}
                        options={filteredTags}
                        title={'Tag'}
                        isTitlePrefix={true}
                        onChange={val => setSelectedTagId(val)}
                      />
                    </div>
                  )}
                  {!isOnGroupsView && (
                    <div className="flex justify-center items-center">
                      <div className="relative">
                        <FormInput
                          placeholder={searchTypes.find(st => st.id === searchType)?.name}
                          value={`${searchString || ''}`}
                          onChange={val => setSearchString(val)}
                          onSubmit={doSearch}
                          inputCssClass={'text-dark-grey text-right input-search'}
                          debounceMs={0}
                        />
                        <div className="absolute top-3 right-2">
                          <button onClick={() => doSearch()}>
                            {/*<FontAwesomeIcon icon={['far', 'search']} size="sm" className="text-primary-blue" />*/}
                            <FontAwesomeIcon icon={['far', searchIcon]} size="sm" className="text-primary-blue" />
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                  {isOnGroupsView && (
                    <div className="flex justify-center items-center">
                      <div className="relative">
                        <FormInput
                          placeholder={'Název'}
                          value={`${groupFilterName || ''}`}
                          onChange={val => setGroupName(val)}
                          onSubmit={() => setGroupFilterName(groupName?.length ? groupName : undefined)}
                          inputCssClass={'text-dark-grey text-right input-search'}
                          debounceMs={0}
                        />
                        <div className="absolute top-3 right-2">
                          <button onClick={() => setGroupFilterName(groupName?.length ? groupName : undefined)}>
                            <FontAwesomeIcon icon={['far', 'search']} size="sm" className="text-primary-blue" />
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                  {!isOnGroupsView && (
                    <div className="flex items-center gap-2">
                      <Dropdown
                        title={' '}
                        hasAllOption={false}
                        options={searchTypes}
                        selectedId={searchType}
                        onChange={val => setSearchType(val)}
                      />
                    </div>
                  )}
                  <FontAwesomeIcon
                    icon={['far', 'times']}
                    title={'Zrušit filtry'}
                    onClick={clearFilters}
                    className="text-primary-blue self-center cursor-pointer"
                  />
                </div>
              </div>
            </div>
            <div className="flex md:hidden">
              <button
                onClick={() => setIsOpen(!isOpen)}
                type="button"
                className="bg-gray-900 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                aria-controls="mobile-menu"
                aria-expanded="false">
                <span className="sr-only">Open main menu</span>
                {!isOpen ? (
                  <svg
                    className="block h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                  </svg>
                ) : (
                  <svg
                    className="block h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>
      )}

      <Transition
        show={isOpen}
        enter="transition ease-out duration-100 transform"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-75 transform"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95">
        {ref => (
          <div className="md:hidden" id="mobile-menu">
            <div ref={ref} className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
              <div className="flex items-center gap-1">
                <FontAwesomeIcon icon={['far', 'warehouse-alt']} className="text-primary-blue self-center" />
                <Dropdown selectedId={selectedDepotId} options={depots} onChange={val => setSelectedDepotId(val)} />
              </div>
              <div className="flex items-center gap-1">
                <FontAwesomeIcon icon={['far', 'cash-register']} className="text-primary-blue self-center" />
                <Dropdown
                  selectedId={selectedCheckoutId}
                  options={checkouts}
                  onChange={val => setSelectedCheckoutId(val)}
                />
              </div>
              <div className="flex items-center gap-1">
                <FontAwesomeIcon icon={['far', 'truck']} className="text-primary-blue self-center" />
                <Dropdown
                  selectedId={selectedCarrierId}
                  options={carriers}
                  onChange={val => setSelectedCarrierId(val)}
                />
              </div>
              <div className="flex items-center gap-1">
                <FontAwesomeIcon icon={['far', 'globe']} className="text-primary-blue self-center" />
                <Dropdown selectedId={selectedCountry} options={countries} onChange={val => setSelectedCountry(val)} />
              </div>
            </div>
          </div>
        )}
      </Transition>
    </nav>
  )
}
