import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Fn } from 'depoto-core/dist/src/models'
import { printTicket, printDocument, Schema, translate, wait, downloadFileWithAuth } from '../lib'
import { useCore } from '../hooks'
import { Btn } from './Btn'
import { Dropdown } from './Dropdown'
import { Address as AddressType, Order, Package } from 'depoto-core/dist/src/entities'
import { NoteModal } from './NoteModal'
import { LinkExternal } from './LinkExternal'

// leave recieved typo (api)
type ProcessStates =
  | 'recieved'
  | 'picking'
  | 'packing'
  | 'packed'
  | 'dispatched'
  | 'delivered'
  | 'returned'
  | 'picking_error'
  | 'cancelled'
const states: ProcessStates[] = ['recieved', 'picking', 'packing', 'packed', 'dispatched', 'delivered', 'returned']
const clientUrl = 'https://app.depoto.cz'
let isProcessingChanges = false

type Props = {
  onUpdate: Fn
}
export const Footer: React.FC<Props> = ({ onUpdate }) => {
  const [pdfDocs, setPdfDocs] = useState<any[]>([])
  const refNoteModal = useRef<NoteModal>(null)
  const refErrorNoteModal = useRef<NoteModal>(null)
  const history = useHistory()
  const { core, currentOrder, setCurrentOrder, isFetching, autoProcessStatusChange } = useCore()
  const currentProcessStatus: ProcessStates = (currentOrder?.processStatus?.id as ProcessStates) || 'recieved'
  const unpicked = currentOrder?.items?.filter(oi => oi.type === 'product' && !oi.picked)
  const unpacked = currentOrder?.items?.filter(oi => oi.type === 'product' && !oi.packed)
  const canUpdateProcessStatus = !(
    (currentProcessStatus === 'picking' && unpicked && unpicked.length > 0) ||
    (currentProcessStatus === 'packing' && unpacked && unpacked.length > 0) ||
    (currentProcessStatus === 'packing' && currentOrder?.carrier?.id.length === 0)
  )
  const btnNextStatusLabel =
    currentProcessStatus === 'recieved'
      ? 'Předat k vyskladnění'
      : currentProcessStatus === 'picking'
      ? 'Předat k zabalení'
      : currentProcessStatus === 'packing'
      ? 'Zabaleno'
      : currentProcessStatus === 'packed'
      ? 'Předat dopravci'
      : currentProcessStatus === 'dispatched'
      ? 'Doručeno'
      : 'Vráceno'
  const backSteps =
    currentProcessStatus === 'recieved' || currentProcessStatus === 'picking' || currentProcessStatus === 'packing'
      ? states.slice(0, currentProcessStatus ? states.indexOf(currentProcessStatus) : 0)
      : currentProcessStatus === 'packed' ||
        currentProcessStatus === 'dispatched' ||
        currentProcessStatus === 'delivered' ||
        currentProcessStatus === 'cancelled'
      ? states.slice(3, currentProcessStatus ? states.indexOf(currentProcessStatus) : 0)
      : []

  const getNextProcessStatus = (currentStatus?: ProcessStates): ProcessStates => {
    const currentIndex = currentStatus ? states.indexOf(currentStatus) : 0
    if (currentIndex === states.length - 1) {
      return states[states.length - 1]
    }
    return states[currentIndex + 1]
  }

  const updateProcessStatus = async (nextStatus: ProcessStates, note?: string) => {
    if (nextStatus === 'packing') {
      if (unpicked && unpicked.length > 0) {
        alert('Chyba: nenapickovane polozky!')
        return
      }
      // note = note ?? (prompt('Poznamka:') || '')
      // if (note === undefined) { // todo do nastaveni, by default nechtit poznamku pri prepnuti na packing
      //   promptNote()
      //   return
      // }
    }
    if (nextStatus === 'packed' && unpacked && unpacked.length > 0) {
      alert('Chyba: nezabalene polozky!')
      return
    }
    note = note ?? ''
    let hasErrors = false
    if (nextStatus === 'packing') {
      const arePackagesOk = await initPackages()
      if (!arePackagesOk) {
        return
      }
    }
    if (nextStatus === 'packed') {
      const packs = currentOrder?.packages || []
      for (const p of packs) {
        if (!p.sent) {
          const resSendPackage = await core?.services.pack.send(p)
          console.log(resSendPackage)
        }
        // const { stderr, error } = await printTicket(p)
        const res = await printTicket(p)
        // console.log(stderr, error, p)
        if (
          (res?.stderr?.length > 0 && res?.stderr.includes('error')) ||
          (res?.error?.length > 0 && res?.error.includes('error'))
        ) {
          hasErrors = true
        }
        await wait()
      }
      await wait(250)
    }
    // todo test in app
    if (hasErrors) {
      alert('Chyba při tisku štítků')
      return
    }
    try {
      await core?.services.order.updateProcessStatus({ id: currentOrder?.id }, nextStatus, note)
    } catch (e) {
      if (Array.isArray(e) && e.length > 0 && !!e[0].message) {
        alert(e.map(m => m.message).join('\n\n'))
        // @ts-ignore
      } else if (e?.status === 500) {
        alert('Chyba při nastavení stavu: error 500')
      }
      setTimeout(async () => {
        const nextOrder = await core.services.order.getById(currentOrder?.id!, Schema.order.detail)
        setCurrentOrder(new Order(nextOrder))
      }, 100)
      return
    }
    await wait()
    onUpdate()
    await wait(250)
    // history.goBack() // todo follow Order to next state or go back?
    const nextIndex = nextStatus ? states.indexOf(nextStatus) : 1
    const currentStatus = states[nextIndex - 1]
    history.push(`/dashboard/${currentStatus || nextStatus}`)
  }

  const cancelReservation = async () => {
    if (confirm('Opravdu zrusit rezervaci?')) {
      await core?.services.order.deleteReservation(currentOrder)
      onUpdate()
      history.push(`/dashboard/${currentProcessStatus}`)
    }
  }

  const initPackages = async (): Promise<boolean> => {
    let warning = 'Chyba pri zakladani baliku:\n\n'
    let isError = false
    if (!currentOrder?.carrier) {
      warning += 'Není nastaven dopravce! \n\n'
      isError = true
    }
    if (currentOrder?.carrier?.requiredShippingAddress && !currentOrder?.shippingAddress) {
      // warning += 'Není nastavena doručovací adresa! \n\n'
      // isError = true // todo do this non blocking for now:
      alert('Není nastavena doručovací adresa!')
    }
    if (isError) {
      alert(warning)
      return false
    }
    if (currentOrder?.packages && currentOrder?.packages.length > 0) {
      return true
    }
    try {
      const res = await core?.services.pack.create({
        carrier: currentOrder?.carrier,
        order: currentOrder,
        items: currentOrder?.items || [],
      })
      if (res && res.id > 0) {
        return true
      } else {
        alert('Chyba pri zakladani baliku')
        return false
      }
    } catch (e) {
      console.warn(e)
      if (Array.isArray(e) && e.length > 0 && !!e[0].message) {
        alert(e.map(m => m.message).join('\n\n'))
        // @ts-ignore
      } else if (e?.status === 500) {
        alert('Chyba při nastavení stavu: error 500')
      }
    }
    return false
  }

  const promptNote = () => {
    refNoteModal?.current?.openModal()
  }

  const promptErrorNote = () => {
    refErrorNoteModal?.current?.openModal()
  }

  // const updateOrder = async () => {
  //   if (!orderUpdates || (orderUpdates && Object.keys(orderUpdates).length === 0)) {
  //     return
  //   }
  //   const updates = { ...orderUpdates }
  //   if (updates?.shippingAddress && currentOrder) {
  //     let addressId = updates.shippingAddress.id
  //     if (addressId > 0) {
  //       await core.services.address.update(updates.shippingAddress)
  //     } else {
  //       const res = await core.services.address.create(updates.shippingAddress)
  //       addressId = res.id
  //       if (addressId) {
  //         await core.services.order.updateOrderPart({
  //           id: currentOrder.id,
  //           shippingAddress: new AddressType({ id: addressId }),
  //         })
  //       }
  //     }
  //   }
  //   delete updates.shippingAddress
  //   await core.services.order.updateOrderPart(updates)
  //   onUpdate()
  // }

  const showOrderDetailInClient = () => {
    console.log(currentOrder?.id)
  }

  useEffect(() => {
    if (
      (currentProcessStatus === 'packing' ||
        currentProcessStatus === 'packed' ||
        currentProcessStatus === 'dispatched') &&
      !currentOrder?.packages?.length
    ) {
      initPackages().then(res => {
        if (res) {
          onUpdate()
        }
      })
    }
  }, [currentProcessStatus, currentOrder?.id, currentOrder?.packages?.length])

  useEffect(() => {
    const possiblePdfUrls = []
    if (currentOrder?.reservationUrl?.length) {
      possiblePdfUrls.push({ id: 'reservationUrl', name: 'objednávka' })
    }
    if (
      currentOrder?.invoiceUrl?.length &&
      (currentProcessStatus === 'packed' || currentProcessStatus === 'dispatched')
    ) {
      possiblePdfUrls.push({ id: 'invoiceUrl', name: 'faktura' })
    }
    if (
      currentOrder?.movePacks?.[0]?.pdfWOPricesUrl?.length &&
      (currentProcessStatus === 'packed' || currentProcessStatus === 'dispatched')
    ) {
      possiblePdfUrls.push({ id: 'movePack', name: 'dodací list' })
    }
    setPdfDocs(possiblePdfUrls)
    if (!autoProcessStatusChange) {
      return
    }
    const checkErrorsBeforeInitingPackages = () => {
      let warning = 'Chyba pri zakladani baliku:\n\n'
      let isError = false
      if (!currentOrder?.carrier) {
        warning += 'Není nastaven dopravce! \n\n'
        isError = true
      }
      if (currentOrder?.carrier?.requiredShippingAddress && !currentOrder?.shippingAddress) {
        warning += 'Není nastavena doručovací adresa! \n\n'
        isError = true
      }
      if (isError) {
        warning += 'Doplňte informace a pokračujte manuálním posunem stavu'
        alert(warning)
      }
      return isError
    }
    if (!isProcessingChanges) {
      if (currentProcessStatus === 'picking' && !unpicked?.length) {
        isProcessingChanges = true // ugly hack prevents multiple firing of same event
        const hasErrors = checkErrorsBeforeInitingPackages()
        setTimeout(() => (isProcessingChanges = false), hasErrors ? 5000 : 300)
        if (!hasErrors) {
          updateProcessStatus('packing')
        }
      }
      if (currentProcessStatus === 'packing' && !unpacked?.length) {
        isProcessingChanges = true // ugly hack prevents multiple firing of same event
        setTimeout(() => (isProcessingChanges = false), 300)
        updateProcessStatus('packed')
      }
    }
  }, [currentOrder])

  const printPdf = (urlType: 'invoiceUrl' | 'reservationUrl' | 'movePack') => {
    // console.log(currentOrder?.[urlType])
    if (urlType === 'movePack') {
      downloadFileWithAuth(currentOrder!.movePacks?.[0]?.pdfWOPricesUrl, 'movePack.pdf', core)
      // printDocument(currentOrder!.movePacks?.[0]?.pdfWOPricesUrl, false, false)
    } else if (currentOrder?.[urlType]) {
      printDocument(currentOrder[urlType], false, false)
    }
  }

  return (
    <footer className={'p-main bg-white border-t border-b border-cool-grey flex justify-between items-center'}>
      <div className={'flex gap-2 items-center'}>
        {/*<Btn*/}
        {/*  onClick={showOrderDetailInClient}*/}
        {/*  isDisabled={isFetching}*/}
        {/*  isLoading={isFetching}*/}
        {/*  cssClass={'btn-outline border-light-grey'}*/}
        {/*  children={'Client'}*/}
        {/*  title={'Client'}*/}
        {/*  icon={'globe'}*/}
        {/*/>*/}
        {!!currentOrder?.id ? (
          <LinkExternal url={`${clientUrl}/order/${currentOrder.id}`} label={`Otevřít v klientu`} />
        ) : null}
        <div className={'ml-28'}>
          <Dropdown
            title={'Tisk pdf'}
            hasAllOption={false}
            options={pdfDocs}
            onChange={val => printPdf(val)}
            isDownDirection={true}
          />
        </div>
        {/*<Btn*/}
        {/*  onClick={() => updateOrder()}*/}
        {/*  isDisabled={isFetching || !orderUpdates}*/}
        {/*  isLoading={isFetching}*/}
        {/*  cssClass={'btn-outline btn-primary'}*/}
        {/*  children={'Uložit'}*/}
        {/*  title={'Uložit'}*/}
        {/*  icon={'check'}*/}
        {/*/>*/}
      </div>
      <NoteModal note={''} ref={refNoteModal} onUpdate={note => updateProcessStatus('packing', note)} />
      <NoteModal
        note={''}
        isError={true}
        ref={refErrorNoteModal}
        onUpdate={note => updateProcessStatus('picking_error', note)}
      />
      <div className={'flex gap-2 items-center'}>
        {backSteps.length > 0 && (
          <Dropdown
            title={'Vrátit stav'}
            selectedId={currentProcessStatus}
            options={backSteps.map(s => ({ id: s, name: translate(s) }))}
            hasAllOption={false}
            onChange={val => updateProcessStatus(val)}
            isDownDirection={true}
          />
        )}
        <Btn
          onClick={promptErrorNote}
          isDisabled={isFetching}
          isLoading={isFetching}
          cssClass={'btn-outline-red border-danger'}
          icon={'triangle-exclamation'}
          children={'Nahlásit chybu'}
          title={'Nahlásit chybu'}
        />
        <Btn
          onClick={cancelReservation}
          isDisabled={isFetching}
          isLoading={isFetching}
          cssClass={'btn-danger'}
          icon={'trash-alt'}
          children={'Zrušit rezervaci'}
          title={'Zrušit rezervaci'}
        />
        <Btn
          onClick={() => updateProcessStatus(getNextProcessStatus(currentProcessStatus))}
          isDisabled={isFetching || !canUpdateProcessStatus}
          isLoading={isFetching}
          cssClass={'btn-primary'}
          icon={'check'}
          children={btnNextStatusLabel}
          title={btnNextStatusLabel}
        />
      </div>
    </footer>
  )
}
