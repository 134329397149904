import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Schema } from '../lib'
import { OrderGroup } from 'depoto-core/dist/src/entities'
import { Btn, Group as GroupComponent, Loader } from '../components'
import { useCore } from '../hooks'

export const Groups = () => {
  const [groups, setGroups] = useState<OrderGroup[]>([])
  const [paginator, setPaginator] = useState({ current: 0, endPage: 0, totalCount: 0 })
  const {
    core,
    isFetching,
    setIsFetching,
    searchString,
    setSearchString,
    itemCount,
    setItemCount,
    selectedTagId,
    groupSortProp,
    groupFilterName,
    setGroupSortProp,
    groupSortDir,
    setGroupSortDir,
  } = useCore()
  const history = useHistory()

  const getGroups = async () => {
    setIsFetching(true)
    const filters: any = {}
    if (selectedTagId) {
      filters.tags = [selectedTagId]
    }
    if (groupFilterName?.length) {
      filters.name = groupFilterName
    }
    const res = await core?.services.order.getOrderGroups(
      {
        sort: groupSortProp,
        direction: groupSortDir,
        filters,
      },
      Schema.orderGroup.list,
    )
    if (res?.items) {
      setGroups(res.items.map((o: any) => new OrderGroup(o)))
      setPaginator(res.paginator)
      setItemCount(res.paginator.totalCount)
    }
    setIsFetching(false)
    // setSearchString('') // leave for now
  }

  const getNextPage = async () => {
    if (paginator.current >= paginator.endPage) {
      return
    }
    // patchState({ isFetching: true }) // todo non-fullscreen loader.. where?
    const res = await core?.services.order.getOrderGroups({ page: paginator.current + 1 }, Schema.orderGroup.list)
    if (res?.items) {
      const nextOrderGroups = [...groups, ...res.items.map((o: any) => new OrderGroup(o))]
      setGroups(nextOrderGroups)
      setPaginator(res.paginator)
      setItemCount(res.paginator.totalCount)
    }
    setIsFetching(false)
    // setSearchString('') // leave for now
  }

  useEffect(() => {
    getGroups()
  }, [selectedTagId, groupSortProp, groupSortDir, groupFilterName])

  return isFetching ? (
    <Loader size="fill-container" />
  ) : (
    <div className="grid xs:grid-cols-3 sm:grid-cols-4 xl:grid-cols-6 gap-1 p-3">
      {groups.length > 0 && groups.map(g => <GroupComponent key={g.id} group={g} />)}
      {paginator.current < paginator.endPage && (
        <Btn
          children={'Načíst další'}
          cssClass={'btn-primary'}
          icon={'arrow-down'}
          title={'Načíst další'}
          onClick={() => getNextPage()}
        />
      )}
    </div>
  )
}
